import { INVITATION_ID, INVITATION_LINK_ID } from '../constants/invitation'
import {
  InviteExistingMemberToOrgType,
  InviteMemberToOrgType,
  RetractInvitationBodyType,
  UpdatePendingMemberRoleType
} from '@dtx-company/true-common/src/types/org'
import { IthacaJwt, JSON_HEADERS } from '@dtx-company/ithaca-sdk/src'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { flowFetch } from '@app/code/src/utils/flowFetch'
import { getRequestHeadersWithAuthHeader } from '.'
import { mutate } from 'swr'
import { orgEndPoints } from '../constants/orgAdmin'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'
import { useCallback } from 'react'
import { useRouter } from 'next/router'
import events from '@dtx-company/inter-app/src/event-tracking/events/orgAdmin'

type Success = boolean

export enum StatusDescription {
  EXISTING_MEMBER = 'existing-member',
  ALREADY_IN_ANOTHER_ORG = 'already-in-another-org',
  INVITE_FOR_ANOTHER_USER = 'invite-for-another-user',
  ALREADY_AT_SEAT_LIMIT = 'already-at-seat-limit'
}

enum ErrorMessage {
  ERROR_SENDING_INVITE = 'There was an error sending the invitation.',
  ERROR_ACCEPTING_INVITE = 'There was an error accepting the invitation, Please try again',
  ERROR_RETRACTING_INVITE = 'There was an error retracting the invitation.',
  USER_IN_ANOTHER_ORG = 'This user is already a member of another organization.',
  INVITE_FOR_ANOTHER_USER = 'This invitation is intended for another user. Please check the email and try again.',
  MEMBER_ALREADY_ADDED = 'The user is already in your organization.',
  REACHED_SEAT_LIMIT = `You've reached your seat invite limit for your plan. Either remove pending invitations or contact sales@flowcode.com to access additional seats.`
}

export const inviteMemberToOrg = async (
  jwt: IthacaJwt | null,
  body: InviteMemberToOrgType,
  options: { notifyOnFailure?: boolean } = { notifyOnFailure: true }
): Promise<string | false> => {
  try {
    const headers = await getRequestHeadersWithAuthHeader(jwt?.token)
    const req = await fetch(orgEndPoints.invitations, {
      method: 'POST',
      body: JSON.stringify({
        ...body
      }),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    })
    const res = await req.json()

    if (res.status === 201) {
      return res.invitationId
    } else if (
      res.status === 409 &&
      res.statusDescription === StatusDescription.ALREADY_IN_ANOTHER_ORG
    ) {
      events.userInvitedAUserFromAnotherOrg()
      options.notifyOnFailure && sendErrorNotification(ErrorMessage.USER_IN_ANOTHER_ORG)
      return false
    } else if (res.status === 409 && res.statusDescription === StatusDescription.EXISTING_MEMBER) {
      events.userInvitedAnExistingMember()
      options.notifyOnFailure && sendErrorNotification(ErrorMessage.MEMBER_ALREADY_ADDED)
      return false
    } else if (
      res.status === 401 &&
      res.statusDescription === StatusDescription.ALREADY_AT_SEAT_LIMIT
    ) {
      options.notifyOnFailure &&
        sendErrorNotification(ErrorMessage.REACHED_SEAT_LIMIT, {
          autoHideDuration: 10000
        })
      events.orgAtSeatLimit()
      return false
    } else {
      options.notifyOnFailure && sendErrorNotification(ErrorMessage.ERROR_SENDING_INVITE)
      return false
    }
  } catch (e) {
    console.error(ErrorMessage.ERROR_SENDING_INVITE, e)
    options.notifyOnFailure && sendErrorNotification(ErrorMessage.ERROR_SENDING_INVITE)
    return false
  }
}

export const inviteExistingMemberToOrg = async (
  jwt: IthacaJwt | null,
  {
    ithacaId: userIthacaId,
    orgId,
    role: newRole,
    extras: orgRoleExtras
  }: InviteExistingMemberToOrgType
): Promise<Success> => {
  try {
    const headers = await getRequestHeadersWithAuthHeader(jwt?.token)
    const req = await fetch(orgEndPoints.memberships, {
      method: 'POST',
      body: JSON.stringify({
        userIthacaId,
        orgId,
        role: newRole,
        ...(!!orgRoleExtras?.length && { orgRoleExtras })
      }),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    })
    const res = await req.json()
    if (res.status === 201) {
      return true
    } else {
      sendErrorNotification(ErrorMessage.ERROR_ACCEPTING_INVITE, res.status)
      return false
    }
  } catch (e) {
    console.error(ErrorMessage.ERROR_ACCEPTING_INVITE, e)
    sendErrorNotification(ErrorMessage.ERROR_ACCEPTING_INVITE)
    return false
  }
}

export const retractInvitation = async (
  jwt: IthacaJwt | null,
  body: RetractInvitationBodyType
): Promise<Success> => {
  try {
    const headers = await getRequestHeadersWithAuthHeader(jwt?.token)
    const req = await fetch(orgEndPoints.invitations, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    })
    const res = await req.json()

    if (res.status === 200) {
      await mutate(`${orgEndPoints.invitations}/${body.orgId}`)
      return true
    } else {
      sendErrorNotification(ErrorMessage.ERROR_RETRACTING_INVITE)
      return false
    }
  } catch (e) {
    console.error(ErrorMessage.ERROR_RETRACTING_INVITE, e)
    sendErrorNotification(ErrorMessage.ERROR_RETRACTING_INVITE)
    return false
  }
}

export const updatePendingMemberRole = async (
  jwt: IthacaJwt | null,
  { orgId, invitationId, orgRole }: UpdatePendingMemberRoleType
): Promise<Success> => {
  try {
    const body: UpdatePendingMemberRoleType = {
      orgId,
      invitationId,
      orgRole
    }

    const headers = await getRequestHeadersWithAuthHeader(jwt?.token)
    const req = await fetch(orgEndPoints.invitations, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    })
    const res = await req.json()

    if (res.status === 200) {
      await mutate(`${orgEndPoints.members}/${orgId}`)
      sendSuccessNotification('Role updated.')
      return true
    }
    sendErrorNotification('There was an error updating the role.')
    return false
  } catch (e) {
    console.error('There was an error updating the role.', e)
    sendErrorNotification('There was an error updating the role.')
    return false
  }
}

export async function acceptInvitationV2(
  invitationId: string,
  ithacaId?: string
): Promise<boolean | void> {
  await flowFetch(
    'POST',
    orgEndPoints.acceptInvitationV2,
    {
      team: 'creatorjourney'
    },
    {
      body: JSON.stringify({ invitationId }),
      headers: { ...JSON_HEADERS, 'x-ithaca-id': ithacaId ?? '' }
    }
  )
}

export async function acceptInvitationLinkV2(invitationLinkId: string): Promise<void> {
  await flowFetch(
    'POST',
    orgEndPoints.acceptInvitationLinkV2,
    {
      team: 'creatorjourney'
    },
    {
      body: JSON.stringify({ invitationLinkId }),
      headers: JSON_HEADERS
    }
  )
}

export function useShouldRedirectToInvitationFlow(): boolean {
  const invitationId = global?.window?.localStorage?.getItem(INVITATION_ID)
  const invitationLinkId = global?.window?.localStorage?.getItem(INVITATION_LINK_ID)

  return Boolean(invitationId || invitationLinkId)
}

export function useRedirectToInvitationFlow(): () => void {
  const invitationId = global?.window?.localStorage?.getItem(INVITATION_ID)
  const invitationLinkId = global?.window?.localStorage?.getItem(INVITATION_LINK_ID)

  const { push } = useRouter()

  return useCallback(() => {
    if (invitationLinkId) {
      push({
        pathname: Routes.ACCEPT_ORG_INVITE,
        query: {
          invitationId: invitationLinkId
        }
      })
    } else if (invitationId) {
      push({
        pathname: Routes.ACCEPT_INVITE,
        query: {
          invitationId
        }
      })
    }
  }, [push, invitationLinkId, invitationId])
}
